.btn,
.btn-action,
.button-cancel,
button {
  background-color: rgba(247, 40, 0, 0.8);
}

.btn-contrast,
.btn-contrast-action,
.button-accept {
  background-color: rgb(247, 40, 0);
}

.btn-white:hover {
  background-color: rgb(247, 40, 0) !important;
  color: white !important;
}

a,
a:visited,
a:hover {
  color: rgb(247, 40, 0);
}

.input:focus {
  border-color: rgb(247, 40, 0);
}

.page-title,
h4 {
  color: rgb(247, 40, 0);
}

.offer-title-box {
  background-image: linear-gradient(
    45deg,
    rgba(247, 40, 0, 0.8),
    rgba(247, 40, 0, 0.5)
  );
}

/* Rules for using icons as black on a light background. */
.material-icons.md-dark {
  color: rgb(247, 40, 0);
}
.material-icons.md-dark.md-inactive {
  color: rgba(247, 40, 0, 0.6);
}
.material-icons.md-dark-neutral {
  color: rgba(247, 40, 0, 0.7);
}

.ui-dialog-titlebar {
  background-color: rgb(247, 40, 0);
}

#loading-icon {
  color: rgb(247, 40, 0);
}
.breadcrumb-item a:visited {
  color: rgb(247, 40, 0) !important;
}

.product_summary {
  border: solid 1px rgb(247, 40, 0);
}
.product_separator {
  border-bottom: 1px solid rgb(247, 40, 0, 0.8);
}

.steps-wrapper li.active {
  color: rgb(247, 40, 0);
}
.steps-wrapper li.active:before {
  background-image: linear-gradient(
    45deg,
    rgba(247, 40, 0, 0.8),
    rgba(247, 40, 0, 1)
  );
  border: none;
}
.steps-wrapper li.active:after {
  background-color: rgb(247, 40, 0);
}
.steps-wrapper li.active.success:before {
  background: rgb(247, 40, 0) url(/img/payments/baseline_done_white_18dp.png)
    no-repeat center;
}
.steps-wrapper li.active.failure:before {
  background: rgb(247, 40, 0)
    url(/img/payments/baseline_report_problem_white_18dp.png) no-repeat center;
}
.text-center-payment {
  color: rgb(247, 40, 0);
}

.tooltipster-noir-customized .tooltipster-content h5 {
  color: rgb(247, 40, 0);
}

#products_summary img {
  height: 32px;
  width: 32px;
}
#selector_img {
  width: 64px;
  height: 64px;
}

.label-container input:checked ~ .checkmark {
  background-color: rgb(247, 40, 0);
}

.checkmark {
  border: solid 3px rgb(247, 40, 0);
}

.form-control:focus {
  color: unset;
  background-color: #fff;
  border-color: rgb(247, 40, 0);
  box-shadow: unset;
}

.marketing_list li .material-icons {
  color: rgb(247, 40, 0);
}

.block_marketing {
  background-image: linear-gradient(
    45deg,
    rgba(247, 40, 0, 1),
    rgba(247, 40, 0, 0.7)
  );
}
